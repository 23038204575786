import { useState } from "react";
import styles from "./Benefit.module.scss";
import { motion } from "framer-motion";
import { ginger } from "../styles/fonts";

const BenefitCard = ({ data }) => {
  const [isActive, setActive] = useState(true);

  return (
    <div className={styles.benefitCard}>
      <div className={styles.imgContainer}>
        {data.id == "1" && <img src={"../assets/Why Us/card1.png"}></img>}
        {data.id == "2" && <img src={"../assets/Why Us/card2.png"}></img>}
        {data.id == "3" && <img src={"../assets/Why Us/card3.png"}></img>}
      </div>
      <div className={styles.contentData}>
        <h3 style={ginger.style}>{data.title}</h3>
        <p>{data.content}</p>
      </div>
    </div>
  );
};

export default BenefitCard;

//  <div
//       className={styles.benefitCard}
//       // onMouseEnter={() => setActive(false)}
//       // initial={{ x: -100, opacity: 0 }}
//       // whileInView={{ x: 0, opacity: 1 }}
//       // transition={{ ease: "linear" }}
//       // viewport={{ amount: 0.8, once: true }}
//     >
//       <div className={styles.container}>
//         <div className={styles.upperElipse} style={{ zIndex: 100 }}>
//           <img src={"../assets/Why Us/card1.png"} height={200}></img>
//         </div>
//         <motion.div
//           className={styles.lowerElipse}
//           initial={{ y: -120, opacity: 0 }}
//           whileInView={{ y: -60, opacity: 1 }}
//           transition={{ ease: "linear" }}
//           viewport={{ amount: 0.8, once: true }}
//         >
//           <p>{data.title}</p>
//         </motion.div>
//       </div>
//     </div>
