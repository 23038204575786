import { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import styles from "./PostSlider.module.scss";

const PostSlider = ({ data }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [imgs, setImgs] = useState(data);
  return (
    <div className={styles.slider_container}>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          className: styles.forwardBtn,
          children: (
            <span>
              <img
                src="../assets/sliderRightArrowBrown.svg"
                className={styles.sliderArrow}
              ></img>
            </span>
          ),
        }}
        backwardBtnProps={{
          className: styles.backwardBtn,
          children: (
            <span>
              <img
                src="../assets/sliderLeftArrowBrown.svg"
                className={styles.sliderArrow}
              ></img>
            </span>
          ),
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
            minWidth: 768,
          },
        ]}
        speed={700}
        easing="linear"
        autoplay={true}
        autoplayDirection="forward"
        autoplayDelay={3000}
      >
        {data
          ? data.map((items) => {
            return (
              <div className={styles.postContainer} key={items.id}>
                <img
                  src={
                    items.attributes
                      ? `https://blogcms.pillupreminder.com${items.attributes.url}`
                      : ""
                  }
                ></img>
              </div>
            );
          })
          : ""}
      </ReactSimplyCarousel>
    </div>
  );
};

export default PostSlider;
