import ReactMarkdown from "react-markdown";
import styles from "./PostContent.module.scss";
import PostSlider from "./PostSlider";
import { smithyxt } from "../styles/fonts";

const PostContent = ({ data }) => {
    return (
        <div className={styles.postContent}>
            {data
                ? data.map((type) => {
                    if (type.__component == "shared.rich-text") {
                        return <ReactMarkdown key={type.id}>{type.body}</ReactMarkdown>;
                    }
                    if (type.__component == "shared.media" && type.file.data) {
                        return (
                            <div className={styles.imgContainer} key={type.id}>
                                <img
                                    src={
                                        type.file && type.file.data
                                            ? `https://blogcms.pillupreminder.com${type.file.data.attributes.url}`
                                            : ""
                                    }
                                ></img>
                            </div>
                        );
                    }
                    if (type.__component == "shared.slider" && type.files.data) {
                        return <PostSlider data={type.files.data} key={type.id} />;
                    }
                    if (type.__component == "shared.quote") {
                        return (
                            <div
                                className={styles.qouteContent}
                                key={type.id}
                                style={smithyxt.style}
                            >
                                <div className={styles.qouteContainer}>
                                    <div className={styles.comma} id={styles.upperComma}>
                                        <img src={"/assets/Blogs/comma1.svg"}></img>
                                    </div>
                                    <div className={styles.content}> {type.body}</div>
                                    <div className={styles.comma} id={styles.lowerComma}>
                                        <img src={"/assets/Blogs/comma3.svg"}></img>
                                    </div>
                                </div>
                                <div className={styles.writer}>- {type.title}</div>
                            </div>
                        );
                    }
                })
                : ""}
        </div>
    );
};

export default PostContent;
