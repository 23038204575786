import { ginger } from "../styles/fonts";
import styles from "./Partners.module.scss";
import img1 from "../public/assets/Partners/emoha.png";

const Partners = () => {
  return (
    <div className={styles.partners}>
      <h1 style={ginger.style}>Our Partners</h1>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.logo}>
            <img src={"../assets/Partners/emoha.png"}></img>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.logo}>
            <img src={"../assets/Partners/asli.png"}></img>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.logo}>
            <img src={"../assets/Partners/meradoc.png"}></img>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.logo}>
            <img src={"../assets/Partners/kochharcare.png"}></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
