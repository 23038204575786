import React, { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import styles from "./LandingSlider.module.scss";

const LandingSlider = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  return (
    <div>
      <div className={styles.slider_container}>
        <ReactSimplyCarousel
          activeSlideIndex={activeSlideIndex}
          onRequestChange={setActiveSlideIndex}
          itemsToShow={1}
          itemsToScroll={1}
          forwardBtnProps={{
            className: styles.forwardBtn,
            children: (
              <span>
                <img
                  src="../assets/sliderRightArrow.svg"
                  className={styles.sliderArrow}
                ></img>
              </span>
            ),
          }}
          backwardBtnProps={{
            className: styles.backwardBtn,
            children: (
              <span>
                <img
                  src="../assets/sliderLeftArrow.svg"
                  className={styles.sliderArrow}
                ></img>
              </span>
            ),
          }}
          dotsNav={{
            show: true,
            itemBtnProps: {
              style: {
                transform: "translateY(-70px)",
                height: 12,
                width: 12,
                margin: "5px",
                borderRadius: "50%",
                border: 0,
                background: "white",
              },
            },
            activeItemBtnProps: {
              style: {
                transform: "translateY(-70px)",
                height: 12,
                width: 12,
                backgroundColor: "#014141",
                margin: "5px",
                borderRadius: "50%",
                border: 0,
              },
            },
          }}
          responsiveProps={[
            {
              itemsToShow: 1,
              itemsToScroll: 1,
              minWidth: 768,
            },
          ]}
          speed={700}
          easing="linear"
          autoplay={true}
          autoplayDirection="forward"
          autoplayDelay={3000}
        >
          <div className={styles.panel}>
            <h2>Unlock a World of Convenience and Savings</h2>
            <p>with Pre-sorted Online Pharmacy!</p>
          </div>
          <div className={styles.panel}>
            <h2>Genuine Medicine Delivered to Your Doorstep</h2>
            <p>Ensuring your Health comes First!</p>
          </div>
          <div className={styles.panel}>
            <h2>Your Trusted Destination for Discounted Medicines</h2>
            <p>Affordable Healthcare at Your Fingertips!</p>
          </div>
          <div className={styles.panel}>
            <h2>Tailored Medication Solutions</h2>
            <p>Customized Prescriptions Just for You!</p>
          </div>
          <div className={styles.panel}>
            <h2>Join the Presorted Medicine Family Today</h2>
            <p>Your Well-Being Matters to Us!</p>
          </div>
        </ReactSimplyCarousel>
      </div>
    </div>
  );
};

export default LandingSlider;
