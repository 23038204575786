import styles from "./HowItWorksSlider.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import { ginger } from "../styles/fonts";

gsap.registerPlugin(ScrollTrigger);

const HowItWorksSlider = () => {
    const panels = useRef([]);
    const panelsContainer = useRef();
    const [mobileWidth, setMobileWidth] = useState("");

    const createPanelsRefs = (panel, index) => {
        panels.current[index] = panel;
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 700) {
                setMobileWidth(true);
                // Stop the animation
                gsap.killTweensOf(panels.current);
                gsap.set(panels.current, { clearProps: "all" });
            } else {
                setMobileWidth(false);
                // Resume the animation if it was previously stopped
                if (panels.current.length > 0) {
                    const totalPanels = panels.current.length;
                    gsap.to(panels.current, {
                        xPercent: -100 * (totalPanels - 1),
                        ease: "none",
                        scrollTrigger: {
                            trigger: panelsContainer.current,
                            pin: true,
                            scrub: 1,
                        },
                    });
                }
            }
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const totalPanels = panels.current.length;
        if (window.innerWidth > 700) {
            gsap.to(panels.current, {
                xPercent: -100 * (totalPanels - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: panelsContainer.current,
                    pin: true,
                    scrub: 1,
                },
            });
        }
    }, []);
    return (
        <div className={styles.outerContainer}>
            <div className={styles.container} ref={panelsContainer}>
                <div
                    className={styles.panel}
                    id={styles.section1}
                    ref={(e) => createPanelsRefs(e, 0)}
                    style={{ backgroundColor: "cayan" }}
                >
                    <div className={styles.leftSection}>
                        <div className={styles.contentContainer}>
                            <p className={styles.count} style={ginger.style}>
                                01
                            </p>
                            <div>
                                <h1 className={styles.heading} style={ginger.style}>
                                    You complete a simple sign-up process
                                </h1>
                                <p className={styles.info}>
                                    We then verify the information you share while keeping it 100%
                                    confidential
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightSection}>
                        <div className={styles.imgContainer}>
                            <img
                                src={"/assets/login.png"}
                                style={{ height: "70%" }}
                                fill={"#6FAAFF"}
                                alt=""
                            />
                        </div>
                    </div>
                </div>

                <section
                    className={styles.panel}
                    id={styles.section2}
                    ref={(e) => createPanelsRefs(e, 1)}
                >
                    <div className={styles.leftSection}>
                        <div className={styles.contentContainer}>
                            <p className={styles.count} style={ginger.style}>
                                02
                            </p>
                            <div>
                                <h1 className={styles.heading} style={ginger.style}>
                                    Receive Confirmation Call
                                </h1>
                                <p className={styles.info}>
                                    Receive Confirmation Call from our Pharmacist. They monitor
                                    the entire process end-to-end right from examining
                                    prescription to preparing your medicine sachets.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightSection}>
                        <div className={styles.imgContainer}>
                            <img
                                src={"/assets/HowItWorks/phonecall.png"}
                                style={{ height: "100%" }}
                                fill={"#6FAAFF"}
                                alt=""
                            />
                        </div>
                    </div>
                </section>
                <section
                    className={styles.panel}
                    id={styles.section3}
                    ref={(e) => createPanelsRefs(e, 2)}
                >
                    <div className={styles.leftSection}>
                        <div className={styles.contentContainer}>
                            <p className={styles.count} style={ginger.style}>
                                03
                            </p>
                            <div>
                                <h1 className={styles.heading} style={ginger.style}>
                                    We sort your & pack you medicines using our gold-standard
                                    machines
                                </h1>
                                <p className={styles.info}>
                                    Your medicines are then packed in your personalised pouches
                                    labelled with your name, medicine name, and quantity, along
                                    with the time and date
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightSection}>
                        <div className={styles.imgContainer}>
                            <img
                                src={"/assets/sachet.png"}
                                style={{ height: "105%" }}
                                fill={"#6FAAFF"}
                                alt=""
                            />
                        </div>
                    </div>
                </section>
                <section
                    className={styles.panel}
                    id={styles.section4}
                    ref={(e) => createPanelsRefs(e, 3)}
                >
                    <div className={styles.leftSection}>
                        <div className={styles.contentContainer}>
                            <p className={styles.count} style={ginger.style}>
                                04
                            </p>
                            <div>
                                <h1 className={styles.heading} style={ginger.style}>
                                    We then dispatch your PillUp boxes to your doorstep.
                                </h1>
                                <p className={styles.info}>
                                    We even arrange automatic refills of your next delivery to
                                    ensure that you never run out of medication.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightSection}>
                        <div
                            className={styles.imgContainer}
                            style={{ backgroundColor: "#E5EFFB" }}
                        >
                            <img
                                src={"/assets/HowItWorks/timeline3.png"}
                                style={{ height: "50%" }}
                                fill={"#6FAAFF"}
                                alt=""
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default HowItWorksSlider;
