import styles from "./Achievements.module.scss";
import Image from "next/image";
import SplitType from "split-type";
import { gsap } from "gsap";
import { useEffect } from "react";

const Achivements = () => {
  useEffect(() => {
    const text = new SplitType("#heading-text", { types: "words, chars" });
    const animation = gsap.timeline();

    animation
      .from("#rightPart", {
        // x: "-100%",
        width: 0,
        duration: 0.8,
        scrollTrigger: {
          trigger: "#infoContent",
        },
      })
      .from("#img", {
        opacity: 0,
        duration: 0.3,
      })
      .from("#countHeading", {
        opacity: 0,
        duration: 0.2,
        // delay: -0.8,
      })
      .from("#infoContent", {
        opacity: 0,
        duration: 0.2,
        // delay: -0.8,
      });
  }, []);
  return (
    <div className={styles.achiev}>
      <div className={styles.capsule}>
        <div className={styles.leftPart}>
          {/* <img src="" alt="" /> */}
          <Image
            src="/assets/Achievement/1.svg"
            id="img"
            width={90}
            height={90}
          />
        </div>
        <div className={styles.rightPart} id="rightPart">
          <h2 id="countHeading">+1000</h2>
          <p id="infoContent">Delivery</p>
        </div>
      </div>
      <div className={styles.capsule}>
        <div className={styles.leftPart}>
          {/* <img src="" alt="" /> */}
          <Image
            src="/assets/Achievement/2.svg"
            id="img"
            width={90}
            height={90}
          />
        </div>
        <div className={styles.rightPart} id="rightPart">
          <h2 id="countHeading">+100000</h2>
          <p id="infoContent">Pouches</p>
        </div>
      </div>
      <div className={styles.capsule}>
        <div className={styles.leftPart}>
          {/* <img src="" alt="" /> */}
          <Image
            src="/assets/Achievement/3.svg"
            id="img"
            // fill
            width={90}
            height={90}
          />
        </div>
        <div className={styles.rightPart} id="rightPart">
          <h2 id="countHeading">+9000</h2>
          <p id="infoContent">Users</p>
        </div>
      </div>
    </div>
  );
};

export default Achivements;
