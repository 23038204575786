import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import styles from "./HorizontalSlider.module.scss";
import { ginger } from "../styles/fonts";
import Link from "next/link";

gsap.registerPlugin(ScrollTrigger);

const HorizontalSlider = () => {
  const panels = useRef([]);
  const panelsContainer = useRef();
  const [mobileWidth, setMobileWidth] = useState("");

  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setMobileWidth(true);
        // Stop the animation
        gsap.killTweensOf(panels.current);
        gsap.set(panels.current, { clearProps: "all" });
      } else {
        setMobileWidth(false);
        // Resume the animation if it was previously stopped
        if (panels.current.length > 0) {
          const totalPanels = panels.current.length;
          gsap.to(panels.current, {
            xPercent: -100 * (totalPanels - 1),
            ease: "none",
            scrollTrigger: {
              trigger: panelsContainer.current,
              pin: true,
              scrub: 1,
            },
          });
        }
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const totalPanels = panels.current.length;
    if (window.innerWidth > 700) {
      gsap.to(panels.current, {
        xPercent: -100 * (totalPanels - 1),
        ease: "none",
        scrollTrigger: {
          trigger: panelsContainer.current,
          pin: true,
          scrub: 1,
        },
      });
    }
  }, []);
  return (
    <div className={styles.outerContainer}>
      <div className={styles.container} ref={panelsContainer}>
        <div
          className={styles.panel}
          id={styles.section1}
          ref={(e) => createPanelsRefs(e, 0)}
        >
          <div className={styles.leftSection} styles={{ color: "#FFD243" }}>
            <img
              src={"/assets/HorizontalSlider/1.png"}
              fill={"#6FAAFF"}
              alt=""
            />
          </div>
          <div className={styles.rightSection}>
            <Link href="/HowItWorks" style={{ color: "black" }}>
              <div className={styles.howItWorksbtn} style={ginger.style}>
                How it Works
              </div>
            </Link>
            <p className={styles.count} style={ginger.style}>
              01
            </p>
            <div>
              <h1 className={styles.heading} style={ginger.style}>
                Pre-sorted <br /> Medicine Doses
              </h1>
              <p className={styles.info}>
                You get your medicines pre-sorted by time and date in ready to
                take pouches as (per your prescription plan). Time to throw away
                those ugly boxes.
              </p>
            </div>
            <div className={styles.scrollbtn} style={ginger.style}>
              Scroll
              <img src={"/assets/rightArrow.svg"} width={25} alt="" />
            </div>
          </div>
        </div>

        <section
          className={styles.panel}
          id={styles.section2}
          ref={(e) => createPanelsRefs(e, 1)}
        >
          <div className={styles.leftSection} styles={{ color: "#FFD243" }}>
            <img
              src={"/assets/HorizontalSlider/2.png"}
              fill={"#6FAAFF"}
              alt=""
              id={styles.slide2Img}
            />
          </div>
          <div className={styles.rightSection}>
            <div className={styles.howItWorksbtn}>
              <Link href="/HowItWorks" style={{ color: "black" }}>
                <u style={ginger.style}>How it Works</u>
              </Link>
            </div>
            <p className={styles.count} style={ginger.style}>
              02
            </p>
            <div>
              <h1 className={styles.heading} style={ginger.style}>
                Medicine
                <br />
                Reminders
              </h1>
              <p className={styles.info}>
                You get timely reminders from us to take your medicines on
                Whatsapp, PillUp app, etc. Need more?
              </p>
            </div>
            <div className={styles.scrollbtn} style={ginger.style}>
              Scroll
              <img src={"/assets/rightArrow.svg"} width={25} alt="" />
            </div>
          </div>
        </section>
        <section
          className={styles.panel}
          id={styles.section3}
          ref={(e) => createPanelsRefs(e, 2)}
        >
          <div className={styles.leftSection} styles={{ color: "#FFD243" }}>
            <img
              src={"/assets/HorizontalSlider/3.png"}
              fill={"#6FAAFF"}
              alt=""
              id={styles.slide3Img}
            />
          </div>
          <div className={styles.rightSection}>
            <div className={styles.howItWorksbtn}>
              <Link href="/HowItWorks" style={{ color: "black" }}>
                <u style={ginger.style}>How it Works</u>
              </Link>
            </div>
            <p className={styles.count} style={ginger.style}>
              03
            </p>
            <div>
              <h1 className={styles.heading} style={ginger.style}>
                Easy to read
                <br />
                prescription
              </h1>
              <p className={styles.info}>
                No need of a fancy medical degree to understand your medicine
                schedule
              </p>
            </div>
            <div className={styles.scrollbtn} style={{ display: "none" }}>
              Scroll
              <img src={"/assets/rightArrow.svg"} width={25} alt="" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HorizontalSlider;
