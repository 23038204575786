import styles from "./CardSlider.module.scss";

const data = [
    {
        id: 1,
        name: "Sanjana Aggarwal",
        tag: "29,Female",
        content1:
            "This app is a game changer for me. I am unable to go out always to buy medicinal products.",
        content2:
            "PillUp gives me the last liberty to shop essential healthcare products from home.",
    },
    {
        id: 2,
        name: "Sanjana Aggarwal",
        tag: "29,Female",
        content1:
            "This app is a game changer for me. I am unable to go out always to buy medicinal products.",
        content2:
            "PillUp gives me the last liberty to shop essential healthcare products from home.",
    },
    {
        id: 3,
        name: "Sanjana Aggarwal",
        tag: "29,Female",
        content1:
            "This app is a game changer for me. I am unable to go out always to buy medicinal products.",
        content2:
            "PillUp gives me the last liberty to shop essential healthcare products from home.",
    },
];

const CardSlider = () => {
    return (
        <div className={styles.cardSlider}>
            <div className={styles.headingContent}>
                <h2>People are loving PillUp</h2>
                <div className={styles.triggerBtn}>
                    <img src={"/assets/BrandStory/leftArrow.svg"}></img>
                    <img src={"/assets/BrandStory/rightArrow.svg"}></img>
                </div>
            </div>
            <div className={styles.sliderContainer}>
                {data.map(({ name, tag, content1, content2, id }) => {
                    return (
                        <div className={styles.card} key={id}>
                            <div className={styles.cardHeadingContainer}>
                                <div className={styles.cardLeft}>
                                    <img
                                        src={"/assets/BrandStory/commaIcon.svg"}
                                        width={20}
                                        height={20}
                                    ></img>
                                </div>
                                <div className={styles.cardRight}>
                                    <img
                                        src={"/assets/BrandStory/user.png"}
                                        width={60}
                                        height={60}
                                    ></img>
                                </div>
                            </div>
                            <div className={styles.cardContent}>
                                <div className={styles.headingCardContent}>
                                    <h3>{name}</h3>
                                    <p>{tag}</p>
                                </div>
                                <div className={styles.review}>
                                    <p>
                                        {content1}
                                        <br /> <br />
                                        {content2}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CardSlider;
