import React, { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import styles from "./VideoSlider.module.scss";
import { ginger } from "../styles/fonts";

const videoLinks = [
  {
    id: 2,
    title: "PillUp Customer Story | Dr. Aruna Broota",
    linkId: "fymHY2CRsD8",
    name: "Aruna",
    info: "Psychologist",
    age: "60",
  },
  {
    id: 2,
    title: "PillUp Customer Story | Sanjana",
    linkId: "m7Jcg6pkjIw",
    name: "Sanjana",
    info: "Working Professional",
    age: "35",
  },
  {
    id: 3,
    title: "PillUp Customer Story | Fawzan",
    linkId: "VrFLwZfic7o",
    name: "Fawzan",
    info: "Associate VP",
    age: "29",
  },
  {
    id: 4,
    title: "PillUp Customer Story | Sarthak",
    linkId: "z8i4ayMzXIg",
    name: "Sarthak",
    info: "College student and Fitness Enthusiast",
    age: "26",
  },
];

const VideoSlider = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [video, setVideo] = useState("fymHY2CRsD8");
  return (
    <div className={styles.slider_container}>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          className: styles.forwardBtn,
          children: (
            <span>
              <img
                src="../assets/sliderRightArrowBrown.svg"
                className={styles.sliderArrow}
              ></img>
            </span>
          ),
        }}
        backwardBtnProps={{
          className: styles.backwardBtn,
          children: (
            <span>
              <img
                src="../assets/sliderLeftArrowBrown.svg"
                className={styles.sliderArrow}
              ></img>
            </span>
          ),
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
            minWidth: 768,
          },
        ]}
        speed={500}
        easing="linear"
      >
        {videoLinks
          ? videoLinks.map(({ id, info, linkId, name }) => {
              return (
                <div className={styles.videoBoxContainer} key={id}>
                  <div
                    className={styles.videoBox}
                    style={{
                      filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.27))",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${linkId}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className={styles.contentInfo}>
                    <h2 style={ginger.style}>{name}</h2>
                    <p>{info}</p>
                  </div>
                </div>
              );
            })
          : ""}
      </ReactSimplyCarousel>
    </div>
  );
};

export default VideoSlider;
