export { default as Footer } from "./Footer";
export { default as Header } from "./Header";
export { default as Hero } from "./Hero";
export { default as Achivements } from "./Achivements";
export { default as Partners } from "./Partners";
export { default as BlogCard } from "./BlogCard";
export { default as BenefitCard } from "./BenefitCard";
export { default as CardSlider } from "./CardSlider";
export { default as HorizontalSlider } from "./HorizontalSlider";
export { default as HowItWorksSlider } from "./HowItWorksSlider";
export { default as LandingSlider } from "./LandingSlider";
export { default as FeedbackSlider } from "./FeedbackSlider";
export { default as VideoSlider } from "./VideoSlider";
export { default as PostContent } from "./PostContent";
export { default as PostSlider } from "./PostSlider";
