import { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import styles from "./FeedbackSlider.module.scss";

const FeedbackSlider = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  return (
    <div className={styles.slider_container}>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          className: styles.forwardBtn,
          children: (
            <span>
              <img
                src="../assets/sliderRightArrowBrown.svg"
                className={styles.sliderArrow}
              ></img>
            </span>
          ),
        }}
        backwardBtnProps={{
          className: styles.backwardBtn,
          children: (
            <span>
              <img
                src="../assets/sliderLeftArrowBrown.svg"
                className={styles.sliderArrow}
              ></img>
            </span>
          ),
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
            minWidth: 768,
          },
        ]}
        speed={700}
        easing="linear"
        autoplay={true}
        autoplayDirection="forward"
        autoplayDelay={5000}
      >
        <div className={styles.feedbackContainer}>
          <div className={styles.feedbackCardContiner}>
            <img
              src={"../assets/Landing/comma.svg"}
              className={styles.comma}
            ></img>
            <img
              src={"../assets/Landing/bgElement.svg"}
              className={styles.bgElement}
            ></img>
            <div className={styles.content}>
              <div className={styles.contentHeading}>
                <p>Rohit, Delhi</p>
                <img src="../assets/Landing/bgHeading.png"></img>
              </div>
              <div className={styles.contentInfo}>
                <p>
                  I am so glad I discovered PillUp! Their online pharmacy
                  service has been a game-changer for me. Not only do they offer
                  genuine medicines, but their prices are also incredibly
                  competitive. It’s a one-stop-shop for all my medication needs.
                </p>
                <div className={styles.rating}>
                  <div className={styles.ratingLeft}>rating</div>
                  <div className={styles.ratingRight}>
                    <div className={styles.profileContainer}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.feedbackContainer}>
          <div className={styles.feedbackCardContiner}>
            <img
              src={"../assets/Landing/comma.svg"}
              className={styles.comma}
            ></img>
            <img
              src={"../assets/Landing/bgElement.svg"}
              className={styles.bgElement}
            ></img>
            <div className={styles.content}>
              <div className={styles.contentHeading}>
                <p>Rohit, Delhi</p>
                <img src="../assets/Landing/bgHeading.png"></img>
              </div>
              <div className={styles.contentInfo}>
                <p>
                  I am so glad I discovered PillUp! Their online pharmacy
                  service has been a game-changer for me. Not only do they offer
                  genuine medicines, but their prices are also incredibly
                  competitive. It’s a one-stop-shop for all my medication needs.
                </p>
                <div className={styles.rating}>
                  <div className={styles.ratingLeft}>rating</div>
                  <div className={styles.ratingRight}>
                    <div className={styles.profileContainer}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactSimplyCarousel>
    </div>
  );
};

export default FeedbackSlider;
