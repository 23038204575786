import styles from "./Hero.module.scss";
import { gsap } from "gsap";
import SplitType from "split-type";
import { useEffect } from "react";
import { ginger } from "../styles/fonts";
import Header from "./Header";
import Link from 'next/link'
import useMobileDetect from "../utils/useMobileDetect";
import { moEngageEvents } from "../constants/moEngageEvents";
import { getSessionStorage } from "../utils/helperMethods";
import { utmCampaignKey, utmSourceKey } from "../constants/constants";

const Hero = () => {
    const currentDevice = useMobileDetect();

    useEffect(() => {
        const text = new SplitType("#heading-text", { types: "words, chars" });
        const animation = gsap.timeline();

        animation
            .from("#slider", {
                y: "100%",
                duration: 1.2,
            })
            .from(text.chars, {
                opacity: 0,
                y: 30,
                duration: 1,
                delay: -0.7,
                stagger: { amount: 0.1 },
            })
            .to("#infoHero", {
                opacity: 1,
                duration: 1,
                delay: -0.8,
            });
    }, []);

    return (
        <div>
            <div style={{ position: "absolute", width: "100vw " }}>
                <Header fontBlack={true} />
            </div>
            <div className={styles.hero}>
                <h2 id="heading-text" style={ginger.style}>
                    We organize, pack and deliver
                    <br />
                    your medicines. Simple!
                </h2>
                <p id="infoHero">
                    Never miss a dose of your
                    <br />
                    medication again.
                </p>
                <Link
                    className="btn"
                    href='/order'
                    rel="noreferrer"
                    style={ginger.style}
                    onClick={() => {
                        window.Moengage.track_event(moEngageEvents.ORDER_NOW_CLICKED, {
                            utm_source: getSessionStorage(utmSourceKey),
                            utm_campaign: getSessionStorage(utmCampaignKey),
                            Source_page: "/",
                            Source: currentDevice.isMobile() ? "mweb" : "web",
                        });
                    }}
                >
                    Order Now
                </Link>
            </div>
            <div className={styles.slider} id="slider"></div>
        </div>
    );
};

export default Hero;
