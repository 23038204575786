import React from "react";
import styles from "./BlogCard.module.scss";
import { ginger } from "../styles/fonts";
import dateFormat from "dateformat";
import Link from "next/link";

const BlogCard = ({ data, id }) => {
  return (
    <Link
      href={`/blogs/${data.slug}`}
      style={{ color: "black", textDecoration: "none" }}
    >
      <div className={styles.card}>
        <div className={styles.imgContainer}>
          <img
            src={
              data.cover && data.cover.data
                ? `https://blogcms.pillupreminder.com${data.cover.data.attributes.url}`
                : "../assets/Blogs/imgthumbnail.png"
            }
          ></img>
        </div>
        <p className={styles.tag}>
          {data && data.category
            ? data.category.data.attributes.name.toUpperCase()
            : ""}
        </p>
        <h3 className={styles.title}>{data ? data.title : ""}</h3>
        <p className={styles.date}>
          {data ? `${dateFormat(data.createdAt, "longDate")}` : ""}
        </p>
      </div>
    </Link>
  );
};

export default BlogCard;
